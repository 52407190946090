import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "@emotion/styled"
import hemneoColors from '../utils/hemneoColors';

const ImageCount = styled.div`
  font-size: 16px;
  text-align: center;
  margin-bottom: 8px;
`

const ImageCards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 8px;
  align-items: stretch;
  justify-content: center;
`
const ImageCard = styled.a`
`

const ImageImg = styled.img`
  width: 100%;
  height: 100%;
  margin: 0;
  border: 1px solid ${hemneoColors.B4};
  border-radius: 8px;
  box-shadow: 0 4px 4px rgba(0,0,0,.3);
`

const NavLink = styled.a`
  display: block;
  margin: 16px 0 0;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: ${hemneoColors.B2};
`

const CategoryTemplate = ({ pageContext}) => {
  const imageBasePathM = 'https://bilder.hemnet.se/images/itemgallery_M/';
  const imageBasePathL = 'https://bilder.hemnet.se/images/1024x/';
  
  return (
    <Layout id="top">
      <SEO title={`Bilder på ${pageContext.categoryName}`} />
      <h1>{pageContext.categoryName}</h1>

      <ImageCount>
        Visar {pageContext.imagePaths.length > 100
          ? `100 av ${pageContext.imagePaths.length}`
          : pageContext.imagePaths.length} { pageContext.imagePaths.length === 1 ? 'bild' : 'bilder' }
      </ImageCount>
      <ImageCards>
      { pageContext.imagePaths.slice(0,100).map(imagePath => (
        <ImageCard href={`${imageBasePathL}${imagePath}`}>
          <ImageImg
            src={`${imageBasePathM}${imagePath}`}
            alt={pageContext.categoryName}
            width="336"
            height="252"
          />
        </ImageCard>
      ))}
      </ImageCards>
      <NavLink href="#top">Upp ↑</NavLink>
      <NavLink href="/">Tillbaka till översikten</NavLink>
    </Layout>
  )
}

export default CategoryTemplate